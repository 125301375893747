"use client";

import {NeoCard} from "@/components/shared";
import {useLiveContext} from "@/context/live/live.context";
import {liveService} from "@/service/live.service";
import {Label} from "flowbite-react";
import {useEffect, useState} from "react";
import {TbAlarmFilled} from "react-icons/tb";

export const TimelineView: React.FC<TimeLineProps> = function ({
  client,
  vehicleSelctedRoute
}) {
  const [dataSource, setDataSource] = useState<any[]>([]);
  const {gpsLocations} = useLiveContext();
  useEffect(() => {
    if (vehicleSelctedRoute) {
      const fetchData = async () => {
        const req = {
          routeId: vehicleSelctedRoute,
          instituteId: client?.institutionId
        };
        await liveService.onGetRouteTimeLine(req).then(response => {
          setDataSource(response);
        });
      };
      fetchData();
    } else {
      setDataSource([]);
    }
  }, [vehicleSelctedRoute]);
  return (
    <div className="h-full max-h-[600px] w-full overflow-auto overflow-y-auto p-5 xl:max-h-[800px]">
      {dataSource.length > 0 ? (
        dataSource.map((item: any, index: any) => (
          <div
            key={index}
            className={`relative flex w-full items-start justify-center gap-5 px-3 py-5 ${index % 2 == 1 ? "flex-row-reverse" : ""}`}
          >
            <div
              className={`flex min-w-[40%] flex-col px-3 ${index % 2 == 1 ? "items-start" : "items-end"}`}
            >
              <Label
                className={`text-xl ${item.isActive ? "text-neo-green" : "text-neo-black"}`}
                value={item?.name}
              />
              <div className="flex items-center gap-1">
                <span className="text-neo-green">
                  <TbAlarmFilled />
                </span>
                <Label
                  className="text-xs text-neo-green"
                  value={item.updatedDate}
                />
              </div>
            </div>
            <div className="relative flex flex-col items-center">
              {/* Timeline Circle */}
              <div className="relative flex h-6 w-6 justify-center rounded-full bg-black dark:bg-white">
                {item.isActive && (
                  <div className="mt-[25%] h-2/4 w-2/4 rounded-full bg-neo-yellow"></div>
                )}
                {!item.isActive && (
                  <div className="mt-[25%] h-2/4 w-2/4 rounded-full bg-neo-red"></div>
                )}
              </div>

              {/* Connector Line */}
              {index !== dataSource.length - 1 && (
                <div
                  id="active-line"
                  className={`absolute left-1/2 top-6 h-[50px] w-0.5 -translate-x-1/2 ${item.isActive ? "bg-neo-black dark:bg-neo-white" : "bg-gray-300"}`}
                  //className={`absolute z-[-1] h-full w-0.5 ${item.isActive ? "bg-neo-black" : "bg-gray-300"}`}
                ></div>
              )}
            </div>
            <NeoCard className="min-w-[40%] p-3">
              <div className="flex items-center gap-1 py-1 ">
                <span className="text-neo-gray">
                  <TbAlarmFilled />
                </span>
                <Label
                  className="text-sm text-neo-gray"
                  value={item.updatedDate}
                />
              </div>
              <div className="grid grid-cols-1">
                {item?.students?.map((x: any, i: any) => (
                  <Label
                    key={i}
                    className={`text-base ${x.swipe ? "text-neo-green" : "text-neo-red"}`}
                    value={x.name}
                  />
                ))}
              </div>
            </NeoCard>
          </div>
        ))
      ) : (
        <div className="flex h-full flex-col items-center justify-center text-center">
          <div className="flex flex-col items-center">
            <svg
              width="290"
              height="290"
              viewBox="0 0 290 290"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M261 145C261 162.228 257.238 178.593 250.482 193.297C232.172 233.254 191.823 261 145 261C98.1768 261 57.8276 233.254 39.5184 193.297C32.7622 178.593 29 162.228 29 145C29 80.9335 80.9335 29 145 29C209.066 29 261 80.9335 261 145Z"
                fill="#DCDCDC"
              />
              <g opacity="0.4" filter="url(#filter0_f_3161_9675)">
                <line
                  x1="226.712"
                  y1="203.937"
                  x2="248.462"
                  y2="225.687"
                  stroke="#DCDCDC"
                  stroke-width="1.45"
                />
              </g>
              <path
                d="M101.638 161.62C101.638 161.476 101.493 160.897 101.349 160.752C100.915 159.016 100.625 157.136 100.625 155.255C100.625 154.532 100.625 153.664 100.77 152.941C100.77 152.796 100.914 152.217 100.914 152.073C101.059 150.771 100.191 149.614 98.8893 149.469C97.5874 149.324 96.4301 150.192 96.2854 151.494C96.2854 151.639 96.1407 152.507 96.1407 152.507C95.9961 153.375 95.9961 154.387 95.9961 155.255C95.9961 157.57 96.2854 159.885 96.864 161.91C96.864 162.054 97.1534 162.778 97.1534 162.922C97.4427 163.935 98.4553 164.658 99.4679 164.658C99.7572 164.658 99.9019 164.658 100.191 164.514C101.348 164.224 102.072 162.922 101.638 161.62Z"
                fill="#FC2A3D"
              />
              <path
                d="M204.93 184.043C204.93 184.043 204.93 185.489 204.93 185.923C204.93 189.251 204.641 191.999 204.352 193.301C204.207 194.603 205.075 195.76 206.232 196.05C206.377 196.05 206.522 196.05 206.666 196.05C207.824 196.05 208.836 195.182 208.981 194.024C209.27 192.578 209.56 189.685 209.56 185.923C209.56 185.634 209.56 185.345 209.56 184.911C209.56 184.766 209.56 184.043 209.56 183.898C209.56 182.596 208.402 181.584 207.1 181.584C205.943 181.584 204.93 182.741 204.93 184.043Z"
                fill="#FC2A3D"
              />
              <path
                d="M112.638 174.784C112.493 174.64 111.915 174.35 111.915 174.35C109.455 172.759 107.43 171.023 105.839 169.143C105.839 169.143 105.405 168.564 105.26 168.419C104.392 167.407 102.946 167.262 101.933 167.985C100.92 168.853 100.776 170.3 101.499 171.313C101.644 171.457 102.078 172.036 102.222 172.181C104.103 174.495 106.562 176.52 109.311 178.256C109.455 178.256 110.034 178.69 110.179 178.835C110.613 179.124 110.902 179.124 111.336 179.124C112.204 179.124 112.927 178.69 113.361 177.967C114.229 176.954 113.795 175.508 112.638 174.784Z"
                fill="#FC2A3D"
              />
              <path
                d="M129.853 180.716C129.708 180.716 128.985 180.571 128.985 180.571C126.526 180.282 123.777 179.558 121.029 178.546C120.884 178.546 120.161 178.256 120.161 178.256C119.004 177.822 117.557 178.401 117.123 179.703C116.689 180.86 117.268 182.307 118.57 182.741C118.57 182.741 119.438 183.03 119.582 183.03C122.765 184.187 125.658 184.911 128.551 185.2C128.696 185.2 129.419 185.345 129.564 185.345C129.708 185.345 129.708 185.345 129.853 185.345C131.01 185.345 132.023 184.477 132.168 183.175C132.168 182.017 131.155 180.86 129.853 180.716Z"
                fill="#FC2A3D"
              />
              <path
                d="M200.445 167.696C201.747 169.866 202.904 172.325 203.628 175.074C203.628 175.219 203.772 175.797 203.917 175.942C204.206 176.954 205.074 177.678 206.232 177.678C206.376 177.678 206.666 177.678 206.81 177.678C208.112 177.388 208.835 176.086 208.546 174.785C208.546 174.64 208.257 173.917 208.257 173.772C207.389 170.589 206.087 167.696 204.496 165.237C204.496 165.092 204.062 164.514 203.917 164.369C203.194 163.356 201.747 163.067 200.59 163.79C199.577 164.514 199.288 165.96 200.011 167.118C200.011 167.118 200.445 167.552 200.445 167.696Z"
                fill="#FC2A3D"
              />
              <path
                d="M177.299 152.073C177.154 152.073 176.286 152.217 176.286 152.217C173.104 152.651 170.21 153.809 167.317 155.689C167.172 155.689 166.594 156.123 166.449 156.268C165.437 156.991 165.147 158.438 165.871 159.595C166.305 160.174 167.028 160.608 167.751 160.608C168.185 160.608 168.764 160.463 169.053 160.174C169.198 160.029 169.776 159.74 169.776 159.74C172.091 158.149 174.405 157.281 176.865 156.991C176.865 156.991 177.588 156.847 177.733 156.847C179.035 156.702 179.903 155.545 179.758 154.243C179.613 152.941 178.601 151.928 177.299 152.073Z"
                fill="#FC2A3D"
              />
              <path
                d="M146.346 176.086C146.201 176.231 145.623 176.52 145.623 176.665C143.308 178.256 140.994 179.414 138.534 180.137C138.534 180.137 137.811 180.282 137.666 180.426C136.365 180.715 135.641 182.017 135.931 183.319C136.22 184.332 137.088 185.2 138.245 185.2C138.39 185.2 138.534 185.2 138.824 185.2C138.968 185.2 139.836 184.911 139.836 184.911C142.874 184.043 145.623 182.741 148.371 180.86C148.371 180.86 149.095 180.426 149.239 180.282C150.252 179.558 150.541 177.967 149.673 176.954C148.95 175.508 147.359 175.218 146.346 176.086Z"
                fill="#FC2A3D"
              />
              <path
                d="M197.697 160.752C198.42 159.74 198.131 158.293 197.118 157.425C196.973 157.281 196.25 156.847 196.25 156.847C193.791 155.255 190.898 153.953 187.715 153.085C187.571 153.085 186.847 152.796 186.703 152.796C185.401 152.507 184.243 153.23 183.809 154.532C183.52 155.834 184.243 156.991 185.545 157.425C185.69 157.425 186.413 157.57 186.413 157.715C189.162 158.438 191.621 159.595 193.646 160.897C193.646 160.897 194.225 161.331 194.37 161.331C194.804 161.62 195.238 161.765 195.672 161.765C196.539 161.765 197.263 161.476 197.697 160.752Z"
                fill="#FC2A3D"
              />
              <path
                d="M159.507 162.488C159.507 162.488 158.929 163.212 158.784 163.212C157.771 164.224 156.904 165.382 155.891 166.394C154.878 167.551 154.01 168.564 152.998 169.721L152.419 170.445C151.551 171.457 151.551 172.904 152.564 173.772C152.998 174.206 153.576 174.35 154.155 174.35C154.734 174.35 155.457 174.061 155.891 173.627C155.891 173.627 156.47 172.904 156.614 172.904C157.627 171.747 158.64 170.589 159.652 169.577C160.52 168.564 161.533 167.407 162.401 166.394L162.979 165.671C163.847 164.658 163.847 163.212 162.835 162.344C161.967 161.476 160.52 161.62 159.507 162.488Z"
                fill="#FC2A3D"
              />
              <path
                d="M209.412 205.742L214.186 201.257C215.054 200.389 215.198 198.798 214.186 197.93C213.318 197.062 211.726 196.918 210.858 197.93L206.229 202.415L202.179 197.93C201.311 196.918 199.864 196.917 198.852 197.785C197.839 198.653 197.839 200.1 198.707 201.113L202.757 205.742L197.984 210.371C197.116 211.239 196.971 212.83 197.984 213.698C198.418 214.132 198.996 214.421 199.72 214.421C200.298 214.421 200.877 214.132 201.311 213.698L205.94 209.214L210.424 214.277C210.858 214.855 211.582 215 212.16 215C212.739 215 213.318 214.855 213.752 214.421C214.764 213.553 214.764 212.107 213.896 211.094L209.412 205.742Z"
                fill="#FC2A3D"
              />
              <path
                d="M102.221 143.972C102.221 143.972 117.121 114.895 120.593 107.951C124.065 101.152 124.065 93.3407 120.593 86.2523C114.662 74.3901 100.051 69.6164 88.1888 75.5475C76.3266 81.4785 71.6976 96.5232 77.484 107.951C83.2704 119.38 95.8558 143.972 95.8558 143.972C97.1577 146.576 100.919 146.576 102.221 143.972ZM86.5976 97.2465C86.5976 90.3028 92.2394 84.6611 99.1831 84.6611C106.127 84.6611 111.768 90.3028 111.768 97.2465C111.768 104.19 106.127 109.832 99.1831 109.832C92.0947 109.687 86.5976 104.19 86.5976 97.2465Z"
                fill="#FC2A3D"
              />
              <defs>
                <filter
                  id="filter0_f_3161_9675"
                  x="220.399"
                  y="197.625"
                  width="34.3734"
                  height="34.3753"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feGaussianBlur
                    stdDeviation="2.9"
                    result="effect1_foregroundBlur_3161_9675"
                  />
                </filter>
              </defs>
            </svg>
            <p className="mt-4 text-lg font-medium text-gray-600">
              Oops! It looks like you haven’t selected a route yet.
            </p>
            <p className="text-sm text-gray-500">
              Pick one from the dropdown to proceed.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
