"use client";

import {NeoButton} from "@/components/forms";
import {NeoCard, NeoSlider} from "@/components/shared";
import {useLiveContext} from "@/context/live/live.context";
import {PlaybackSpeedConfig} from "@/data";
import {PlayAction} from "@/libs/enums/live";
import {liveService} from "@/service/live.service";
import {Image} from "@nextui-org/react";
import {Label} from "flowbite-react";
import {useEffect, useState} from "react";
import {FaPause, FaPlay} from "react-icons/fa6";
import {FiMinus, FiPlus} from "react-icons/fi";

interface PlayBackProps {
  dateRange: DateRange | undefined;
  handlePlaybackUpdate?: (data: TPlaybackData) => void;
  setPlaybackPolylinePaths?: (data: NeoMap[]) => void;
}
interface SplitLabelProps {
  url: string;
  name: string;
  startValue?: string | number;
  endValue?: string | number;
}

export const PlayBack: React.FC<PlayBackProps> = ({
  handlePlaybackUpdate,
  setPlaybackPolylinePaths,
  dateRange
}) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [playIntervalId, setPlayIntervalId] = useState<any>(null);
  const [playSpeed, setPlaySpeed] = useState<number>(1);
  const [playbackData, setPlaybackData] = useState<TPlaybackData[]>([]);
  const [currentPlaybackIndex, setCurrentPlaybackIndex] = useState<number>(0);
  const [maxPlaybackIndex, setMaxPlaybackIndex] = useState<number>(0);
  const {selectedVehicle} = useLiveContext();

  useEffect(() => {
    const fetchData = async () => {
      const req = {
        clientId: selectedVehicle?.vehicleId,
        fromDate: dateRange?.from?.getTime() || null,
        toDate: dateRange?.to?.getTime() || null
      };
      await liveService.onGetPlaybackHistory(req).then(response => {
        if (response?.playbackData?.length > 0) {
          convertPlaybackData(response.playbackData);
          setMaxPlaybackIndex(response.playbackData.length - 1);
        } else ResetPlayBackData();
      });
    };
    dateRange?.from &&
      dateRange?.to &&
      selectedVehicle?.vehicleId &&
      fetchData();
  }, [dateRange, selectedVehicle]);

  useEffect(() => {
    triggerPlayback();
  }, [isPlaying, playSpeed]);

  useEffect(() => {
    handlePlaybackUpdate &&
      handlePlaybackUpdate(playbackData[currentPlaybackIndex]);
  }, [currentPlaybackIndex]);

  useEffect(() => {
    let result: NeoMap[] = [];
    if (playbackData.length > 0) {
      result = playbackData
        .filter(x => x && x.coordinates) // Filter out null or undefined elements
        .map(x => x.coordinates);
    }
    setPlaybackPolylinePaths && setPlaybackPolylinePaths(result);
  }, [playbackData]);

  const triggerPlayback = () => {
    playIntervalId && clearInterval(playIntervalId);
    isPlaying && startPlayback();
  };

  const onChangePlaySpeed = (action: PlayAction) => {
    const result = playSpeed + (action === PlayAction.INCREASE ? 0.5 : -0.5);
    setPlaySpeed(Math.max(0.5, Math.min(3, result)));
  };

  const startPlayback = () => {
    const speed = PlaybackSpeedConfig.find(
      x => x.speedFactor === playSpeed
    )?.value;
    let count = currentPlaybackIndex;
    const intervalId = setInterval(() => {
      if (maxPlaybackIndex === count) {
        clearInterval(intervalId); // Stop the interval
        setCurrentPlaybackIndex(0);
        setIsPlaying(false);
      } else setCurrentPlaybackIndex(++count);
    }, speed);
    setPlayIntervalId(intervalId);
  };

  const ResetPlayBackData = () => {
    setMaxPlaybackIndex(0);
    setCurrentPlaybackIndex(0);
    setPlaybackData([]);
    setIsPlaying(false);
  };

  const convertPlaybackData = (data: any[]) => {
    let totalDis = 0;
    const result = data?.map(x => ({
      date: converDateTimeFormate(x?.ti),
      coordinates: {
        lat: x?.lat,
        lng: x?.lon,
        ti: x?.ti,
        sp: x?.sp,
        di: x?.sp
      },
      speed: x?.sp,
      distance: ((totalDis = totalDis + x?.di) / 1000).toFixed(2)
    }));
    setPlaybackData(result);
  };

  const converDateTimeFormate = (date: number) => {
    const newTimestamp = new Date(date);
    return `${newTimestamp.getHours().toString().padStart(2, "0")}:${newTimestamp.getMinutes().toString().padStart(2, "0")}:${newTimestamp.getSeconds().toString().padStart(2, "0")} | ${newTimestamp.getDate().toString().padStart(2, "0")}-${(newTimestamp.getMonth() + 1).toString().padStart(2, "0")}-${newTimestamp.getFullYear()}`;
  };

  return (
    <>
      <NeoCard className="absolute bottom-12 right-14 flex w-1/4 flex-col rounded-xl p-3">
        <div className="flex items-center gap-2">
          <NeoButton
            onClick={() => setIsPlaying(!isPlaying)}
            className="text-2xl text-neo-gray"
            isIconOnly
            variant="light"
            aria-label={isPlaying ? "Pause" : "Play"}
          >
            {isPlaying ? <FaPause /> : <FaPlay />}
          </NeoButton>
          <NeoSlider
            size="sm"
            color="warning"
            aria-label="playback"
            value={currentPlaybackIndex}
            minValue={0}
            maxValue={maxPlaybackIndex}
            onChange={event => {
              setCurrentPlaybackIndex(Number(event));
            }}
            onChangeEnd={triggerPlayback}
          />
        </div>
        <div className="flex items-center justify-between pl-2 *:text-sm *:text-neo-gray">
          <Label
            className="w-3/5"
            value={playbackData[currentPlaybackIndex]?.date || "NA"}
          />
          <div className="flex w-2/5 flex-none items-center justify-end gap-1 *:w-max *:min-w-max *:px-1 *:text-sm *:text-neo-gray">
            <NeoButton
              onClick={() => onChangePlaySpeed(PlayAction.DECREASE)}
              isIconOnly
              variant="light"
            >
              <FiMinus />
            </NeoButton>
            <Label value={`${playSpeed} x`} />
            <NeoButton
              onClick={() => onChangePlaySpeed(PlayAction.INCREASE)}
              isIconOnly
              variant="light"
            >
              <FiPlus />
            </NeoButton>
          </div>
        </div>
        <SplitLabel
          url="/icons/speed.svg"
          name="speed"
          startValue={`${playbackData[currentPlaybackIndex]?.speed || 0} kmph`}
          endValue={"Speed"}
        />
        <SplitLabel
          url="/icons/distance.svg"
          name="distance"
          startValue={`${playbackData[currentPlaybackIndex]?.distance || 0} km`}
          endValue={"Total Distance"}
        />
      </NeoCard>
    </>
  );
};

const SplitLabel: React.FC<SplitLabelProps> = ({
  url,
  name,
  startValue,
  endValue
}) => {
  return (
    <div className="mt-1 flex items-center pl-2">
      <Image className="mr-2 h-6 w-6" src={url} alt={name} radius="none" />
      {startValue && <span className="text-md font-medium">{startValue}</span>}
      {endValue && (
        <span className="text-xs text-neo-gray">
          <span className="mx-2">|</span>
          {endValue}
        </span>
      )}
    </div>
  );
};
